import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, BoxProps, Flex } from 'rebass/styled-components'
import { Section, SectionBox, SeparatorLine } from './base'

function ImgWrapper(props: BoxProps): React.ReactElement {
  return (
    <Box
      width={1}
      px={[1, 3, 4]}
      sx={{
        alignSelf: 'center',
      }}
      {...props}
    >
      <Img {...props} />
    </Box>
  )
}
export const fluidImage6 = graphql`
  fragment fluidImage6 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function AssociateImages(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      KiwisForKiwiWS1: file(
        relativePath: { eq: "images/KiwisForKiwiWS1.png" }
      ) {
        ...fluidImage6
      }
      KiwiBankFintechWS1: file(
        relativePath: { eq: "images/KiwiBankFintechWS1.png" }
      ) {
        ...fluidImage6
      }
      MossCafeWS: file(relativePath: { eq: "images/MossCafeWS.png" }) {
        ...fluidImage6
      }
      NemWS: file(relativePath: { eq: "images/NemWS.png" }) {
        ...fluidImage6
      }
      DuncanCotterillWS: file(
        relativePath: { eq: "images/DuncanCotterillWS.png" }
      ) {
        ...fluidImage6
      }
      FinTechNZWS: file(relativePath: { eq: "images/FinTechNZWS.png" }) {
        ...fluidImage6
      }
      DeloitteWS: file(relativePath: { eq: "images/DeloitteWS.png" }) {
        ...fluidImage6
      }
      StarshipWS: file(relativePath: { eq: "images/StarshipWS.png" }) {
        ...fluidImage6
      }
      UyocBLogoWS: file(relativePath: { eq: "images/UyocBLogoWS.png" }) {
        ...fluidImage6
      }
      CreativeHQWS: file(relativePath: { eq: "images/CreativeHQWS.png" }) {
        ...fluidImage6
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <SeparatorLine color="secondary.main" mb={[4, 4, 5]} />
      <Box px={[2, 3, 0]}>
        <Section
          mt={[1, 2, 0]}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
          }}
        >
          <ImgWrapper fluid={data.CreativeHQWS.childImageSharp.fluid} />
          <ImgWrapper fluid={data.KiwisForKiwiWS1.childImageSharp.fluid} />
          <ImgWrapper fluid={data.DeloitteWS.childImageSharp.fluid} />
          <ImgWrapper fluid={data.KiwiBankFintechWS1.childImageSharp.fluid} />
          <ImgWrapper fluid={data.FinTechNZWS.childImageSharp.fluid} />
          <ImgWrapper fluid={data.StarshipWS.childImageSharp.fluid} />
        </Section>
      </Box>
      <Section p={[0, 1, 2]} pt={[2, 1, 4]}>
        <ImgWrapper fluid={{}} />
        <ImgWrapper fluid={data.DuncanCotterillWS.childImageSharp.fluid} />
        <ImgWrapper fluid={data.UyocBLogoWS.childImageSharp.fluid} />
        <ImgWrapper fluid={data.NemWS.childImageSharp.fluid} />
        <ImgWrapper fluid={data.MossCafeWS.childImageSharp.fluid} />
        <ImgWrapper fluid={{}} />
      </Section>
      <SeparatorLine color="secondary.main" my={[4, 5, 5]} />
    </SectionBox>
  )
}
