import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box } from 'rebass/styled-components'
import { Section, SectionBox, SeparatorLine, SubtitleMain } from './base'

function ImgWrapper(props: any): React.ReactElement {
  return (
    <Box
      width={[1, 1 / 3, 1 / 7]}
      px={[1, 3, 2]}
      sx={{
        alignSelf: 'center',
      }}
      {...props}
    >
      <Img {...props} />
    </Box>
  )
}

export const fluidImage6 = graphql`
  fragment fluidImage16 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export function MediaLogos(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      SixtyOneBit: file(relativePath: { eq: "images/SixtyOneBit.png" }) {
        ...fluidImage16
      }
      Computerworld: file(relativePath: { eq: "images/Computerworld.png" }) {
        ...fluidImage16
      }
      EdmundHill: file(relativePath: { eq: "images/EdmundHill.png" }) {
        ...fluidImage16
      }
      FinderAu: file(relativePath: { eq: "images/FinderAu.png" }) {
        ...fluidImage16
      }
      Idealog: file(relativePath: { eq: "images/Idealog.png" }) {
        ...fluidImage16
      }
      Medium: file(relativePath: { eq: "images/Medium.png" }) {
        ...fluidImage16
      }
      NBR: file(relativePath: { eq: "images/NBR.png" }) {
        ...fluidImage16
      }
      NemMedia: file(relativePath: { eq: "images/NemMedia.png" }) {
        ...fluidImage16
      }
      OneNews: file(relativePath: { eq: "images/OneNews.png" }) {
        ...fluidImage16
      }
      PRwire: file(relativePath: { eq: "images/PRwire.png" }) {
        ...fluidImage16
      }
      Paymentsnz: file(relativePath: { eq: "images/Paymentsnz.png" }) {
        ...fluidImage16
      }
      RNZ: file(relativePath: { eq: "images/RNZ.png" }) {
        ...fluidImage16
      }
      TED: file(relativePath: { eq: "images/TED.png" }) {
        ...fluidImage16
      }
      TheSpinoff: file(relativePath: { eq: "images/TheSpinoff.png" }) {
        ...fluidImage16
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <Box px={['.7rem', 4, 0]}>
        <SeparatorLine color="secondary.main" />
        <SubtitleMain py={[1, 3, 4]} fontSize={[2, 4, 8]} fontWeight="black">
          As seen on
        </SubtitleMain>
        <Section mt={[1, 2, 5]}>
          <ImgWrapper fluid={data.PRwire.childImageSharp.fluid} />
          <ImgWrapper fluid={data.SixtyOneBit.childImageSharp.fluid} />
          <ImgWrapper fluid={data.NBR.childImageSharp.fluid} />
          <ImgWrapper fluid={data.Computerworld.childImageSharp.fluid} />
          <ImgWrapper fluid={data.Medium.childImageSharp.fluid} />
          <ImgWrapper fluid={data.NemMedia.childImageSharp.fluid} />
          <ImgWrapper fluid={data.EdmundHill.childImageSharp.fluid} />
        </Section>

        <Section pt={[2, 1, 4]}>
          <ImgWrapper fluid={data.Idealog.childImageSharp.fluid} />
          <ImgWrapper fluid={data.OneNews.childImageSharp.fluid} />
          <ImgWrapper fluid={data.FinderAu.childImageSharp.fluid} />
          <ImgWrapper fluid={data.Paymentsnz.childImageSharp.fluid} />
          <ImgWrapper fluid={data.RNZ.childImageSharp.fluid} />
          <ImgWrapper fluid={data.TED.childImageSharp.fluid} />
          <ImgWrapper fluid={data.TheSpinoff.childImageSharp.fluid} />
        </Section>
        <SeparatorLine color="secondary.main" my={[4, 5, 6]} />
      </Box>
    </SectionBox>
  )
}
