import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import {
  AccessButtonReverse,
  Section,
  SectionBox,
  SubtitleCenter,
  Title,
} from '../base'
import CustomerJoinLink from '../CustomerJoinLink'

export function PPPWrapper(props: any): React.ReactElement {
  return (
    <Box width={[1, 2 / 3, 1]} px={[0, 1, 2]} textAlign="center">
      <Img {...props} />
      <SubtitleCenter pt={2} fontSize={[3, 6, 7]}>
        {props.text}
      </SubtitleCenter>
    </Box>
  )
}
export const fluidImage2 = graphql`
  fragment fluidImage2 on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function PPP(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "images/people.png" }) {
        ...fluidImage2
      }
      planet: file(relativePath: { eq: "images/planet.png" }) {
        ...fluidImage2
      }
      purpose: file(relativePath: { eq: "images/purpose.png" }) {
        ...fluidImage2
      }
      hand: file(relativePath: { eq: "images/hand.png" }) {
        ...fluidImage2
      }
    }
  `)
  return (
    <SectionBox bg="primary.main">
      <Title
        color="primary.text"
        fontSize={[7, 10, 13]}
        mt={[0, 0, 5]}
        fontWeight="black"
      >
        Pay with Choice
      </Title>
      <SubtitleCenter pt={[0, 0, 2]} fontSize={[4, 4, 7]} fontWeight="black">
        It&#39;s simple, free, easy and it helps all New Zealanders.
      </SubtitleCenter>

      <Flex justifyContent="center">
        <Section
          pt={[4, 4, 6]}
          width={['100%', '80%', '56%']}
          ml={[0, 5, 0]}
          sx={{ gridGap: [1, 2, 6] }}
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          <PPPWrapper fluid={data.people.childImageSharp.fluid} text="People" />
          <PPPWrapper fluid={data.planet.childImageSharp.fluid} text="Planet" />
          <PPPWrapper
            fluid={data.purpose.childImageSharp.fluid}
            text="Purpose"
          />
        </Section>
      </Flex>

      <Flex justifyContent="center" px="4" pt={[2, 3, 5]}>
        <Box width={[1 / 4, 1 / 8, 1 / 24]}>
          <Img fluid={data.hand.childImageSharp.fluid} />
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <AccessButtonReverse
          width={[4 / 5, 1 / 2, 2 / 5]}
          sx={{ borderRadius: 20 }}
          mt={[2, 2, 0]}
          mb={[1, 4, 5]}
          fontSize={[1, 2, 4]}
          fontWeight="semibold"
          justifyContent="center"
        >
          <CustomerJoinLink textColor="primary.main" />
        </AccessButtonReverse>
      </Flex>
    </SectionBox>
  )
}
