import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import * as React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { AssociateImages } from '../components/Associates'
import { MediaLogos } from '../components/MediaLogos'
import { withLayout, LayoutProps } from '../components/Layout'
import { PPP } from '../components/index/PPP'
import { Container, ExternalLink, Masthead } from '../components/base'
import { SimpleFree } from '../components/index/SimpleFree'

interface IndexProps extends LayoutProps {
  data: {
    HomeInsta: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}
const IndexPage = (props: IndexProps) => (
  <Container>
    <Masthead p="4">
      <PPP />
    </Masthead>
    <SimpleFree />
    <AssociateImages />
    <Box
      width={[1 / 10, 1 / 16, 1 / 56]}
      mt={[1, 2, 2]}
      pb={[1, 2, 2]}
      alignSelf="center"
    >
      <Img fluid={props.data.HomeInsta.childImageSharp.fluid} />
    </Box>
    <Flex
      flexDirection="row"
      justifyContent="center"
      fontFamily="body"
      fontWeight="bold"
      fontSize={[2, 5, 1]}
      pb={[3, 0, 0]}
    >
      Follow&nbsp;
      <ExternalLink href="https://www.instagram.com/choicetopay">
        <Text color="primary.main">@choicetopay</Text>
      </ExternalLink>
      &nbsp;on Instagram
    </Flex>
    <MediaLogos />
  </Container>
)
export default withLayout(IndexPage)

export const pageQuery = graphql`
  query {
    HomeInsta: file(relativePath: { eq: "images/HomeInsta.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
