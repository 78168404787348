import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Container, SectionBox, TextImage } from '../base'

export function SimpleFree(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      HomeLogoInlineNew: file(
        relativePath: { eq: "images/HomeLogoInlineNew.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HomePhone: file(relativePath: { eq: "images/HomePhone.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  function HomeLogoWrapper(props: any): React.ReactElement {
    return (
      <Box
        pb={2}
        fontSize={[1, 3, 4]}
        fontFamily="body"
        fontWeight="normal"
        lineHeight={['1rem', '1.6rem', '1.6rem']}
      >
        {props.text1}
        <TextImage width={[2 / 5, 1 / 6, 1 / 6]}>
          <Img fluid={data.HomeLogoInlineNew.childImageSharp.fluid} />
        </TextImage>
        {props.text2}
      </Box>
    )
  }

  function HomePhoneWrapper(): React.ReactElement {
    return (
      <Box
        sx={{
          maxHeight: '80rem',
          maxWidth: '40rem',
        }}
      >
        <Img fluid={data.HomePhone.childImageSharp.fluid} />
      </Box>
    )
  }
  return (
    <SectionBox bg="primary.text">
      <Flex px={[1, 1, 2]} py={2} flexDirection="row" flexWrap="wrap">
        <Box
          width={1}
          sx={{
            display: 'grid',
            gridGap: [3, 4, 0],
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Container width={[1, 1, 4 / 7]}>
            <HomePhoneWrapper />
          </Container>
          <Container width={1} pt={2} fontFamily="body" mt={[0, 6, 5]}>
            <HomeLogoWrapper
              text1="Pay with&nbsp;"
              text2="&nbsp;at the counter instead of a credit or debit card."
            />
            <HomeLogoWrapper text1="We will redistribute half of the transaction fee to a charity of your&nbsp;" />
            <Text fontSize={[2, 3, 6]} fontWeight="black" color="primary.main">
              It&#39;s simple, free, and it helps kiwis.
            </Text>
          </Container>
        </Box>
      </Flex>
    </SectionBox>
  )
}
